//Navbar
export const firstName = "Caeleb";
export const lastName = "Koharjo";

//About me
export const traits = [
  { trait: "caeleb.ethnicity", value: "'1/2 Indonesian 1/2 Singaporean'" },
  { trait: "caeleb.age", value: "'23 years old'" },
  {
    trait: "caeleb.status",
    value: "'5th Year Undergraduate @ The University of Britsh Columbia'",
  },
  { trait: "caeleb.favoriteFood", value: "'Beef Pepper Rice @ Pepper Lunch'" },
  { trait: "caeleb.favoriteColor", value: "'Orange'" },
  {
    trait: "caeleb.hobbies",
    value: "['weightlifting', 'gaming', 'programming', 'eating']",
  },
];

export const aboutMeContext = [
  "👋 Hey there, I'm Caeleb!",

  "📚 A fourth-year Computer Science undergraduate pursuing a degree in Computer Science at the University of British Columbia." +
    " With a passion for Software Engineering, I'm continually exploring the diverse realms of computer science.",

  "🌍 Originally from the vibrant city-state of 🇸🇬 Singapore, I'm an international student seeking to make my mark on the tech world.",

  "💪 Outside of the classroom, I find my balance by hitting the gym, honing my skillsin competitive video games, immersing myself in captivating manga, " +
    "and constantly expanding my knowledge about the ever-evolving world of computers.",

  "😄 Thanks for stopping by!",
];

//Work Experience

export const workExperienceTitle = "Work Experience";

//Project Experience

export const projectExperienceTitle = "Project Experience";

//Contact

export const email = "caelebkoharjo@gmail.com";
export const github = "caelebk";
